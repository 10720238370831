import addClickHandler from 'mex_components/app/scripts/events/addClickHandler';

addClickHandler(document, function(event) {
  const target = event.target;
  const hasSubnav = target.closest('.early-america-nav-item--subnav');
  const isLink = target.parentNode === hasSubnav &&
    target.classList.contains('early-america-nav-link');

  // Clicked on the link of this item
  if(hasSubnav && isLink) {
    const subnav = hasSubnav.querySelector('.early-america-nav-subnav');
    subnav.classList.toggle('early-america-nav-subnav--open');
    hasSubnav.classList.toggle('early-america-nav-item--open');
    event.preventDefault();
  }

  // Clicking anywhere outside a subnav closes all menus
  [...document.querySelectorAll('.early-america-nav-item--open')].forEach(el => {
    if(el !== hasSubnav) {
      el.classList.remove('early-america-nav-item--open');
      const subnav = el.querySelector('.early-america-nav-subnav');
      subnav.classList.remove('early-america-nav-subnav--open');
    }
  });
});
